// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout, HomeFrontPage } from '@/layouts'
import { renderMenuIcon } from '@/components/common/menu-icon'
// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  403: () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
  404: () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
  500: () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),

  Role: () => import('@/views/employmentUser/Role'),

  // account
  materialData: () => import('@/views/material/index'),
  msgDemand: () => import('@/views/msg/index'),
  Bidding: () => import('@/views/bidding/index'),
  BiddingDetail: () => import('@/views/bidding/detail/index'),
  RecruitEvaluate: () => import('@/views/recruitUse/evaluate/index'),
  Fevaluate: () => import('@/views/flexibileStaffing/Fevaluate/index'),
  RecruitAttendance: () => import('@/views/recruitUse/attendance/index'),
  Fattendance: () => import('@/views/flexibileStaffing/Fattendance/index'),
  RecruitIndex: () => import('@/views/recruitUse/recruit/index'),
  FrecruitIndex: () => import('@/views/flexibileStaffing/Frecruit/index'),
  RecruitUseCommission: () => import('@/views/recruitUse/commission/index'),
  Fcommission: () => import('@/views/flexibileStaffing/Fcommission/index'),
  materialDemandOrder: () => import('@/views/materialDemand/order/index'),
  materialDemandOrderDetail: () => import('@/views/materialDemand/order/detail'),
  materialDemandAddress: () => import('@/views/materialDemand/address/index'),
  biddingManagerIndex: () => import('@/views/biddingManager/index'),
  TenderingBiddingIndex: () => import('@/views/TenderingBidding/index'),
  learnMsgIndex: () => import('@/views/learnMsg/msg/index'),
  learnMsgorderMsg: () => import('@/views/learnMsg/orderMsg/index'),
  workbenchModle: () => import('@/views/workbench/modle/index'),
  workbenchNotice: () => import('@/views/workbench/notice/index'),
  workbenchProject: () => import('@/views/workbench/project/index'),
  workbenchRecruit: () => import('@/views/workbench/contract/recruit'),
  workbenchPurchase: () => import('@/views/workbench/contract/index'),
  materialShopSearch: () => import('@/views/materialShop/search/index'),
  materialShopDetail: () => import('@/views/materialShop/detail/index'),
  materialShopCar: () => import('@/views/materialShop/car/index'),
  materialShopOrder: () => import('@/views/materialShop/order/index'),
  workDemandIndex: () => import('@/views/workDemand/index'),
  systemEnterprise: () => import('@/views/system/enterprise/index'),
  systemAccount: () => import('@/views/system/account/index'),
  msgManagerRecruitUse: () => import('@/views/msgManager/msgRecruitUse/index'),
  msgManagerDemand: () => import('@/views/msgManager/msgDemand/index'),
  msgShopIndex: () => import('@/views/msgShop/index'),
  msgShopDetail: () => import('@/views/msgShop/detail'),
  recruitUseManager: () => import('@/views/recruitUse/recruitUseManager/index'),
  FrecruitUseManager: () => import('@/views/flexibileStaffing/FrecruitUseManager/index'),
  videoMicroClass: () => import('@/views/videoMicroClass/index')
}
export const asyncRouterMap = [
  {
    path: '/recruitUse',
    name: '业务外包管理',
    meta: {
      title: '业务外包管理',
      icon: renderMenuIcon('icon-yonggong')
    },
    component: BasicLayout,
    children: [
      {
        path: '/recruitUse/recruitUseManager',
        name: 'recruitUseManager',
        meta: {
          title: '岗位需求管理'
        },
        component: constantRouterComponents.recruitUseManager
      },
      {
        path: '/recruitUse/recruitIndex',
        name: 'recruitIndex',
        meta: {
          title: '岗位招聘管理'
        },
        component: constantRouterComponents.RecruitIndex
      },
      {
        path: '/recruitUse/recruitAttendance',
        name: 'recruitAttendance',
        meta: {
          title: '岗位考勤管理'
        },
        component: constantRouterComponents.RecruitAttendance
      },
      {
        path: '/recruitUse/recruitUseCommission',
        name: 'recruitUseCommission',
        meta: {
          title: '岗位佣金管理'
        },
        component: constantRouterComponents.RecruitUseCommission
      },
      {
        path: '/recruitUse/salary-ticket',
        name: 'GWSalaryTicket',
        meta: {
          title: '佣金取票管理'
        },
        component: () => import('@/views/recruitUse/salary-ticket/index')
      },
      {
        path: '/recruitUse/recruitEvaluate',
        name: 'recruitEvaluate',
        meta: {
          title: '岗位评价管理'
        },
        component: constantRouterComponents.RecruitEvaluate
      }
    ]
  },
  {
    path: '/flexibileStaffing',
    name: '灵活用工管理',
    meta: {
      title: '灵活用工管理',
      icon: renderMenuIcon('icon-yonggong')
    },
    component: BasicLayout,
    children: [
      {
        path: '/flexibileStaffing/FrecruitUseManager',
        name: 'FrecruitUseManager',
        meta: {
          title: '用工需求管理'
        },
        component: constantRouterComponents.FrecruitUseManager
      },
      {
        path: '/flexibileStaffing/FrecruitIndex',
        name: 'FrecruitIndex',
        meta: {
          title: '用工招聘管理'
        },
        component: constantRouterComponents.FrecruitIndex
      },
      {
        path: '/flexibileStaffing/Fattendance',
        name: 'Fattendance',
        meta: {
          title: '用工考勤管理'
        },
        component: constantRouterComponents.Fattendance
      },
      {
        path: '/flexibileStaffing/Fcommission',
        name: 'Fcommission',
        meta: {
          title: '用工佣金管理'
        },
        component: constantRouterComponents.Fcommission
      },
      {
        path: '/flexibileStaffing/salary-ticket',
        name: 'WBSalaryTicket',
        meta: {
          title: '佣金取票管理'
        },
        component: () => import('@/views/flexibileStaffing/salary-ticket/index')
      },
      {
        path: '/flexibileStaffing/Fevaluate',
        name: 'Fevaluate',
        meta: {
          title: '用工评价管理'
        },
        component: constantRouterComponents.Fevaluate
      }
    ]
  },
  {
    path: '/insurance',
    name: 'insurance',
    meta: {
      title: '保险管理',
      icon: renderMenuIcon('icon-icon_xuqiu')
    },
    component: BasicLayout,
    children: [
      {
        path: '/insurance/index',
        name: 'insuranceIndex',
        meta: {
          title: '保险管理'
        },
        component: () => import('@/views/insurance/index/index.vue')
      },
      {
        path: '/insurance/insurance-create',
        name: 'insuranceCreate',
        meta: {
          title: '创建保单'
        },
        component: () => import('@/views/insurance/insurance-create/index.vue')
      }
    ]
  },
  {
    path: '/express-insurance',
    name: 'ExpressInsurance',
    meta: {
      title: '保险补章管理',
      icon: renderMenuIcon('icon-icon_xuqiu')
    },
    component: BasicLayout,
    children: [
      {
        path: '/express-insurance/order',
        name: 'ExInOrder',
        meta: {
          title: '保险订单'
        },
        component: () => import('@/views/express-insurance/order.vue')
      }
    ]
  },
  {
    path: '/msgManager',
    name: 'msgManager',
    meta: {
      title: '资料需求管理',
      icon: renderMenuIcon('icon-icon_xuqiu')
    },
    component: BasicLayout,
    children: [
      {
        path: '/msgManager/msgManagerDemand',
        name: 'msgManagerDemand',
        meta: {
          title: '资料需求管理'
        },
        component: constantRouterComponents.msgManagerDemand
      },
      {
        path: '/msgManager/msgManagerRecruitUse',
        name: 'msgManagerRecruitUse',
        meta: {
          title: '资料用工管理'
        },
        component: constantRouterComponents.msgManagerRecruitUse
      }
    ]
  },
  {
    path: '/materialDemand',
    name: 'materialDemand',
    meta: {
      title: '物资采购',
      icon: renderMenuIcon('icon-gouwuche')
    },
    component: BasicLayout,
    children: [
      {
        path: '/materialDemand/materialDemandOrder',
        name: 'materialDemandOrder',
        meta: {
          title: '订单管理'
        },
        component: constantRouterComponents.materialDemandOrder
      },
      {
        path: '/materialDemand/materialDemandOrderDetail',
        name: 'materialDemandOrderDetail',
        hidden: true,
        meta: {
          title: '订单详情'
        },
        component: constantRouterComponents.materialDemandOrderDetail
      },
      {
        path: '/materialDemand/materialDemandAddress',
        name: 'materialDemandAddress',
        meta: {
          title: '收货地址管理'
        },
        component: constantRouterComponents.materialDemandAddress
      }
    ]
  },
  {
    path: '/biddingManager',
    name: 'biddingManager',
    meta: {
      title: '招投标管理',
      icon: renderMenuIcon('icon-zhaotoubiao')
    },
    component: BasicLayout,
    redirect: '/biddingManager/biddingManagerIndex',
    children: [
      {
        path: '/biddingManager/biddingManagerIndex',
        name: 'biddingManagerIndex',
        meta: {
          title: '招投标管理'
        },
        component: constantRouterComponents.biddingManagerIndex
      },
      {
        path: '/biddingManager/TenderingBiddingIndex',
        name: 'TenderingBiddingIndex',
        meta: {
          title: '站内招投标承接公告'
        },
        component: constantRouterComponents.TenderingBiddingIndex
      },
      {
        path: '/biddingManager/w-bidder',
        name: 'Wbidder',
        meta: {
          title: '中标公告'
        },
        component: () => import('@/views/w-bidder/index.vue')
      },
      {
        path: '/biddingManager/r-bidder',
        name: 'Rbidder',
        meta: {
          title: '响应项目'
        },
        component: () => import('@/views/r-bidder/index.vue')
      }
    ]
  },
  {
    path: '/learnMsg',
    name: 'learnMsg',
    meta: {
      title: '学习资料',
      icon: renderMenuIcon('icon-ziliaoku')
    },
    component: BasicLayout,
    children: [
      {
        path: '/learnMsg/learnMsgIndex',
        name: 'learnMsgIndex',
        meta: {
          title: '资料查看'
        },
        component: constantRouterComponents.learnMsgIndex
      },
      {
        path: '/learnMsg/learnMsgorderMsg',
        name: 'learnMsgorderMsg',
        meta: {
          title: '资料订单'
        },
        component: constantRouterComponents.learnMsgorderMsg
      }
    ]
  },
  {
    path: '/vip',
    component: BasicLayout,
    meta: {
      title: '企业会员',
      icon: renderMenuIcon('icon-gongzuotai')
    },
    children: [
      {
        path: '/vip/order',
        name: 'VipOrder',
        meta: {
          title: '会员订单'
        },
        component: () => import('@/views/vip-order/index')
      }
    ]
  },
  {
    path: '/invoice',
    component: BasicLayout,
    name: 'Invoice',
    meta: {
      title: '发票管理',
      icon: renderMenuIcon('icon-gongzuotai')
    },
    children: [
      {
        path: '/invoice/index',
        name: 'InvoiceIndex',
        meta: {
          title: '发票管理'
        },
        component: () => import('@/views/invoice/index')
      }
    ]
  },
  {
    path: '/pay-stubs',
    component: BasicLayout,
    meta: {
      title: '工资条发放台账',
      icon: renderMenuIcon('icon-gongzuotai')
    },
    children: [
      {
        path: '/pay-stubs/order',
        name: 'PayStubs',
        meta: {
          title: '企业工资条管理'
        },
        component: () => import('@/views/pay-stubs/index')
      }
    ]
  },
  {
    path: '/workbench',
    name: 'workbench',
    meta: {
      title: '工作台',
      icon: renderMenuIcon('icon-gongzuotai')
    },
    component: BasicLayout,
    children: [
      {
        path: '/workbench/workbenchModle',
        name: 'workbenchModle',
        meta: {
          title: '模板管理'
        },
        component: constantRouterComponents.workbenchModle
      },
      {
        path: '/workbench/contractManagement',
        name: 'contractManagement',
        meta: {
          title: '合同管理'
        },
        component: constantRouterComponents.workbenchPurchase
      },
      {
        path: '/workbench/workbenchProject',
        name: 'workbenchProject',
        meta: {
          title: '项目部管理'
        },
        component: constantRouterComponents.workbenchProject
      },
      {
        path: '/workbench/workbenchNotice',
        name: 'workbenchNotice',
        meta: {
          title: '消息通知'
        },
        component: constantRouterComponents.workbenchNotice
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    meta: {
      title: '系统设置',
      icon: renderMenuIcon('icon-xitongshezhi')
    },
    component: BasicLayout,
    children: [
      {
        path: '/system/systemAccount',
        name: 'systemAccount',
        meta: {
          title: '账号管理'
        },
        component: constantRouterComponents.systemAccount
      },
      {
        path: '/system/app-users',
        name: 'AppUsers',
        meta: {
          title: 'App账号管理'
        },
        component: () => import('@/views/system/app-users')
      },
      {
        path: '/system/add-app-users',
        name: 'AddUsers',
        meta: {
          title: 'App账号注册新增'
        },
        component: () => import('@/views/system/app-users/add-user.vue')
      },
      {
        path: '/system/systemEnterprise',
        name: 'systemEnterprise',
        meta: {
          title: '企业名片'
        },
        component: constantRouterComponents.systemEnterprise
      },
      {
        path: '/system/menu',
        name: 'menu',
        meta: {
          title: '菜单管理'
        },
        component: constantRouterComponents.Menu
      },
      {
        path: '/system/role',
        name: 'role',
        meta: {
          title: '角色管理'
        },
        component: constantRouterComponents.Role
      },
      {
        path: '/system/dict',
        name: 'dict',
        meta: {
          title: '字典管理'
        },
        component: constantRouterComponents.Dict
      }
    ]
  },
  {
    path: '/train',
    name: 'Train',
    meta: {
      title: '培训月管理',
      icon: renderMenuIcon('icon-xitongshezhi')
    },
    component: BasicLayout,
    children: [
      {
        path: '/train/train-user',
        name: 'TrainUser',
        meta: {
          title: '培训人员管理'
        },
        component: () => import('@/views/train/train-user/index.vue')
      },
      {
        path: '/train/train-plan',
        name: 'TrainPlan',
        meta: {
          title: '培训计划'
        },
        component: () => import('@/views/train/train-plan')
      },
      {
        path: '/train/train-static',
        name: 'TrainStatic',
        meta: {
          title: '培训统计'
        },
        component: () => import('@/views/train/train-static')
      }
    ]
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    component: UserLayout,
    redirect: '/login/index',
    children: [
      {
        path: '/login/index',
        name: 'login',
        meta: {
          title: '登录'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: '/login/register',
        name: 'register',
        meta: {
          title: '注册'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: '/login/forget',
        name: 'forget',
        meta: {
          title: '找回密码'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/forget')
      }
    ]
  },
  {
    path: '/home',
    component: HomeFrontPage,
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        name: 'home',
        meta: {
          title: '首页'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/home/index')
      },
      {
        path: '/home/balance',
        name: 'balance',
        meta: {
          title: '储值中心'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/userBalance/index')
      },
      {
        path: '/home/member',
        name: 'member',
        meta: {
          title: '会员中心'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/member')
      }
    ]
  },
  {
    path: '/employment',
    name: 'employment',
    component: HomeFrontPage,
    redirect: '/employment/workDemandIndex',
    children: [
      {
        path: '/employment/workDemandIndex',
        name: 'workDemandIndex',
        meta: {
          title: '发布岗位需求'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/workDemand/index')
      },
      {
        path: '/employment/workStaffing',
        name: 'workStaffing',
        meta: {
          title: '发布项目承揽'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/workStaffing/index')
      }
    ]
  },
  {
    path: '/supplies',
    name: 'supplies',
    component: HomeFrontPage,
    redirect: '/supplies/materialData',
    children: [
      {
        path: '/supplies/materialData',
        name: 'materialData',
        meta: {
          title: '用推荐清单采购'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/material/index')
      },
      {
        path: '/supplies/supplies-shop',
        name: 'suppliesShop',
        meta: {
          title: '物资商城'
        },
        component: () => import('@/views/materialShop/search/index')
      },
      {
        path: '/supplies/supplies-details',
        name: 'suppliesDetails',
        meta: {
          title: '商品详情',
          menu: [
            {
              title: '物资商城',
              path: '/supplies/supplies-shop'
            }
          ]
        },
        component: () => import('@/views/materialShop/detail/index')
      },
      {
        path: '/supplies/supplies-order',
        name: 'suppliesOrder',
        meta: {
          title: '提交订单',
          menu: [
            {
              title: '物资商城',
              path: '/supplies/supplies-shop'
            }
          ]
        },
        component: () => import('@/views/materialShop/order/index')
      },
      {
        path: '/supplies/supplies-car',
        name: 'suppliesCar',
        meta: {
          title: '购物车',
          menu: [
            {
              title: '物资商城',
              path: '/supplies/supplies-shop'
            }
          ]
        },
        component: () => import('@/views/materialShop/car/index')
      }
    ]
  },
  {
    path: '/material',
    name: 'material',
    component: HomeFrontPage,
    redirect: '/material/msgDemand',
    children: [
      {
        path: '/material/msgDemand',
        name: 'msgDemand',
        meta: {
          title: '方案征集'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/msg/index')
      },
      {
        path: '/material/material-shop',
        name: 'materialShop',
        meta: {
          title: '资料商城'
        },
        component: () => import('@/views/msgShop/index')
      },
      {
        path: '/material/material-details',
        name: 'materialDetails',
        meta: {
          title: '资料详情',
          menu: [
            {
              title: '资料商城',
              path: '/material/material-shop'
            }
          ]
        },
        component: () => import('@/views/msgShop/detail')
      },
      {
        path: '/material/supplies-order',
        name: 'materialOrder',
        meta: {
          title: '提交订单',
          menu: [
            {
              title: '资料商城',
              path: '/material/material-shop'
            }
          ]
        },
        component: () => import('@/views/msgShop/order')
      }
    ]
  },
  {
    path: '/bidding',
    name: 'bidding',
    component: HomeFrontPage,
    redirect: '/bidding/bidding-list',
    children: [
      {
        path: '/bidding/bidding-list',
        name: 'biddingList',
        meta: {
          title: '招投标信息'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/bidding/index')
      },
      {
        path: '/bidding/bidding-details',
        name: 'biddingDetails',
        meta: {
          title: '招投标详情',
          menu: [
            {
              title: '招投标',
              path: '/bidding/bidding-list'
            }
          ]
        },
        component: () => import('@/views/bidding/detail/index')
      },
      {
        path: '/bidding/bidding-list-enter',
        name: 'biddingListEnter',
        meta: {
          title: '站内招投标'
        },
        component: () => import(/* webpackChunkName: "user" */ '@/views/bidding-enter/index')
      },
      {
        path: '/bidding/bidding-details-enter',
        name: 'biddingDetailsEnter',
        meta: {
          title: '招投标详情'
        },
        component: () => import('@/views/bidding-enter/detail/index')
      }
    ]
  },
  {
    path: '/find-someone',
    component: HomeFrontPage,
    children: [
      {
        path: '/find-someone/index',
        name: 'FindSomeone',
        meta: {
          title: '找人'
        },
        component: () => import('@/views/find-someone/index')
      }
    ]
  },
  {
    path: '/finance',
    component: HomeFrontPage,
    children: [
      {
        path: '/finance/index',
        name: 'Finance',
        meta: {
          title: '找钱'
        },
        component: () => import('@/views/finance/index')
      }
    ]
  },
  // 视频微课
  {
    path: '/videoMicro',
    component: HomeFrontPage,
    redirect: '/videoMicro/videoMicroClass',
    children: [
      {
        path: '/videoMicro/videoMicroClass',
        name: 'videoMicroClass',
        meta: {
          title: '视频微课'
        },
        component: () => import('@/views/videoMicroClass/index')
      },
      {
        path: '/videoMicro/videoMicroClassInfo',
        name: 'videoMicroClassInfo',
        meta: {
          title: '观看视频'
        },
        component: () => import('@/views/videoMicroClass/videoMicroClassInfo.vue')
      }
    ]
  },
  // 执业云课堂
  {
    path: '/video',
    component: HomeFrontPage,
    redirect: '/video/list',
    children: [
      {
        path: '/video/list',
        name: 'videoList',
        meta: {
          title: '职技云课堂'
        },
        component: () => import('@/views/video/list')
      },
      {
        path: '/video/details',
        name: 'videoDetails',
        meta: {
          title: '观看视频'
        },
        component: () => import('@/views/video/details.vue')
      }
    ]
  },
  {
    path: '/enterpriseInfor',
    name: 'enterpriseInfor',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/enterpriseInfor')
  }
]

export const staticRouterMap = [
  {
    path: '/',
    component: BasicLayout,
    hidden: true,
    children: [
      {
        path: '/403',
        component: constantRouterComponents['403']
      }
    ]
  },
  {
    path: '/404',
    component: constantRouterComponents['404']
  },
  {
    path: '*',
    redirect: '/404'
  }
]

export const allRouterMap = [...constantRouterMap, ...asyncRouterMap, ...staticRouterMap]
