import request from '@/utils/request'

const userApi = {
  Login: '/api/enterprise/enterpriseClientAuthController/login',
  LoginSms: '/api/enterprise/enterpriseClientAuthController/checkCodeLogin',
  // Login: '/api/auth/login',
  Logout: '/api/auth/logout',
  ForgePassword: '/api/enterprise/enterpriseClientAuthController/retrievePassword',
  Register: '/api/enterprise/enterpriseClientAuthController/register',
  twoStepCode: '/api/auth/2step-code',
  SendSms: '/api/enterprise/employmentMessage/EnterpriseSendSmsController/sendSms',
  SendSmsErr: '/api/account/sms_err',
  // get my info
  UserInfo: '/api/back/employmentUser/sys-user/queryCurrentEnterpriseUserInfo',
  // UserMenu: '/api/back/employmentUser/menu/user',
  UserMenu: '/api/back/employmentUser/menu/getUserMenus'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

// 忘记密码
export function forgePassword (parameter) {
  return request({
    url: userApi.ForgePassword,
    method: 'post',
    data: parameter
  })
}
// 注册
export function register (parameter) {
  return request({
    url: userApi.Register,
    method: 'post',
    data: parameter
  })
}
// 企业端注册时添加企业信息认证
export function addEnterpriseInfo (parameter) {
  return request({
    url: `/api/back/employmentEmploy/enterprise_info/addEnterpriseInfo`,
    method: 'POST',
    data: parameter
  })
}
// 查询当前登录用户的企业名片详情
export function enterpriseCard (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSystemManagementController/getCurrentUserEnterpriseInfo`,
    method: 'get',
    params: parameter
  })
}
// 上传企业相册
export function addEnterprisePhotoAndLogo (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseSystemManagementController/addEnterprisePhotoAndLogo`,
    method: 'POST',
    data: parameter
  })
}
export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function loginSms (parameter) {
  return request({
    url: userApi.LoginSms,
    method: 'post',
    data: null,
    params: {
      ...parameter
    }
  })
}

export function getSmsCaptcha (parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    params: parameter
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get'
    // headers: {
    //   'Content-Type': 'application/json;charset=UTF-8'
    // }
  })
}

export function getCurrentUserNav () {
  return request({
    url: userApi.UserMenu,
    method: 'get',
    params: {
      clientId: process.env.VUE_APP_CLIENT_ID
    }
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return request({
    url: userApi.twoStepCode,
    method: 'post',
    data: parameter
  })
}
